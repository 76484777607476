import React, { Component, createRef } from "react";
import { View, Map, Overlay } from "ol";
import { fromLonLat } from "ol/proj";
import { ScaleLine, defaults as defaultControl } from "ol/control";
import { defaults as defaultInteraction } from "ol/interaction";

import { keyBy, get, isEmpty, first, last } from "lodash";

import Slider from "rc-slider";
import "rc-slider/assets/index.css";

import {
	getLayersFromConfig,
	addFeatureFromInfos,
	getCapabilitiesDimension,
	getLayerByName,
	getAllRealVisibleLayers,
	getOverlayText,
} from "../containers/utils/carto";

import BaseMapsSelector from "../components/BaseMapsSelector";

import meta_com from "../data/meta_com.json";

import InfosBox from "./InfosBox";

import { getBaseLayers } from "../containers/utils/basemaps";

import config from "../config";

import { Button, Tooltip, Overlay as ReactOverlay } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo } from "@fortawesome/free-solid-svg-icons";
const customFontIconStyle = {
	position: "absolute",
	transform: "translate(-50%, -50%)",
};

const BASE_LAYERS = getBaseLayers(config.baselayers);
const TIME_BASE_LAYERS = getBaseLayers(config.baselayers);
const NAVIGATION_LAYERS = getLayersFromConfig(
	config?.navigation.filter((l) => l.navigation && l.navigation.length)
);
const TIME_NAVIGATION_LAYERS = getLayersFromConfig(
	config?.navigation.filter((l) => l.navigation && l.navigation.length)
);

const zoomSizes = config.zoomSizes;

const sliderStyle = {
	handleStyle: { borderColor: "#000" },
	railStyle: { backgroundColor: "#686f77" },
	dotStyle: { borderColor: "#686f77" },
};

class Carlitto extends Component {
	constructor(props) {
		super(props);
		this.olMap = createRef();
		this.olTimeMap = createRef();
		this.infoBtnRef = createRef();
		const defaultViewInfos = {
			center: fromLonLat([55.54, -21.11]),
			projection: "EPSG:3857",
			resolution: zoomSizes?.default,
			constrainResolution: true,
		};
		// use maxResolution if set
		if (zoomSizes?.minResView) {
			defaultViewInfos.minResolution = zoomSizes.minResView;
		}
		if (zoomSizes?.maxResView) {
			defaultViewInfos.maxResView = zoomSizes.maxResView;
		}
		this.defaultView = new View(defaultViewInfos);
	}

	showLayer = null;

	carMap = null;

	timeMap = null;

	overlay = null;
	overlayFeatureNom = null;

	clickedFeature = null;

	clickedLayer = null;

	clickedLayerUrl = "";

	state = {
		showBaseMapSelector: false,
		showLayer: "",
		selectedYear: "",
		selectedYearCompare: "",
		timeActivated: false,
		blCompare: [],
		fullTimeList: [],
	};

	/**
	 * Manage Overlay tooltip
	 * @param {any} event
	 */
	moveHandler(event) {
		let displayOverlay = false;
		let overlayText = null;
		this.carMap.forEachFeatureAtPixel(
			event.pixel,
			(feature) => {
				displayOverlay = config.displayOverlay;
				const nom = feature.get("nom");
				const code = feature.get("insee");
				if ((!nom && !code) || this.overlayFeatureNom === nom) {
					return feature;
				}
				const sitePilote = feature.get("site_pilote");
				const insee = feature.get("insee");
				overlayText = getOverlayText(
					nom,
					code,
					get(this.commNbIndic, `${insee}.nb_indic`),
					sitePilote
				);
				this.overlayFeatureNom = nom;
				return feature;
			},
			{
				layerFilter: (l) => {
					return (
						l.get("name") !== "selectedLayer" &&
						l.get("visible") &&
						!l.get("isBaseLayer")
					);
				},
			}
		);

		if (displayOverlay) {
			this.overlay.getElement().style.display = "";
			this.overlay.setPosition(event.coordinate);
			if (overlayText) {
				this.overlay.getElement().innerHTML = overlayText;
			}
		} else {
			this.overlay.getElement().style.display = "none";
		}
		document.body.style.cursor = displayOverlay ? "pointer" : "";
	}

	/**
	 * Find layer from visibility status
	 * @param {ol.Map} targetMap
	 * @returns
	 */
	getSelectedLayer(targetMap) {
		// will only return visible layer as layerFilter option
		const visibleLayers = getAllRealVisibleLayers(targetMap);
		const isResolutionVisible = visibleLayers.map(
			(l) => l.getProperties().name
		);
		const clickableLayersName = visibleLayers.filter(
			(l) => l.getProperties().clickable
		);
		// will return only one layer because we can only display one layer by navigation mode
		return clickableLayersName.filter((x) =>
			isResolutionVisible.includes(x.getProperties().name)
		)[0];
	}

	/**
	 * From map layers, get clicked layer URL according to resolution and visibility status
	 * @param {ol.Map} targetMap
	 * @param {any} event
	 * @returns
	 */
	getUrlFromLayer = (targetMap, event) => {
		const viewResolution = targetMap.getView().getResolution();
		let clickedLayer = this.getSelectedLayer(targetMap);
		if (!clickedLayer) return;
		// will calcul GetFeatureInfo URL usefull to get info by map pixel clicked
		const clickedParams = clickedLayer.getSource().getParams();
		let clickedLayerUrl = clickedLayer
			.getSource()
			.getFeatureInfoUrl(event.coordinate, viewResolution, "EPSG:2975", {
				INFO_FORMAT: "application/json",
				QUERY_LAYERS: clickedParams.layers || clickedParams.LAYERS,
			});
		return {
			clickedLayer: clickedLayer,
			clickedLayerUrl: clickedLayerUrl,
		};
	};

	/**
	 * Get infos from clicked map coordinates
	 * @param {any} event
	 */
	updateClick(event) {
		// get layer URL for first map
		let infosClickedLayer = this.getUrlFromLayer(this.carMap, event);

		if (infosClickedLayer?.clickedLayer) {
			// get layer URL for second compare map
			let layerUrlCompare = this.getUrlFromLayer(
				this.timeMap,
				event
			).clickedLayerUrl;
			this.clickedLayerUrl = infosClickedLayer.clickedLayerUrl;
			this.clickedLayer = infosClickedLayer.clickedLayer;
			this.clickedFeature = {
				coordinate: event.coordinate,
				pixel: event.pixel,
			};
			// update store to refresh FeatureBox, MetaBox panels with correct info
			if (
				this.state.yearsListAvailable &&
				this.state.yearsListAvailable.length > 1
			) {
				this.props.onCarClick(this.clickedLayerUrl, layerUrlCompare);
			} else {
				// if only one year, just load one chart data
				this.props.onCarClick(this.clickedLayerUrl, "");
			}
		}
	}

	/**
	 * To show overlay and research vector feature to highlight.
	 * Trigger panel info next.
	 * @param {any} event
	 */
	clickHandler(event) {
		let selLayer = getLayerByName(this.carMap, "selectedLayer");
		selLayer.getSource().clear();
		this.setState({ showBaseMapSelector: false });
		if (!isEmpty(event)) {
			this.updateClick(event);
		}
	}

	/**
	 * Update state with selected date
	 * @param {integer} year selected value
	 * @param {string} key to update date from left or right slider
	 */
	onSelectYear(year, key) {
		this.setState({
			[key]: year || this.state[key],
		});
	}

	/**
	 * Close info panel
	 * @param {Object} props
	 */
	closeInfoPanel(props) {
		this.clickedFeature = null;
		props.onCarClick("");
		this.clickHandler();
	}

	/**
	 * See React doc to get more detail about Hooks
	 * https://fr.reactjs.org/docs/react-component.html#componentdidmount
	 */
	componentDidMount() {
		window.onresize = function () {
			this.carMap && this.carMap.updateSize();
			this.timeMap && this.timeMap.updateSize();
		};
		this.commNbIndic = keyBy(meta_com, (c) => c.id_com);

		this.carMap = new Map({
			target: this.olMap.current,
			layers: [...BASE_LAYERS, ...NAVIGATION_LAYERS],
			controls: defaultControl({ collapsible: false }).extend([
				new ScaleLine(),
			]),
			interactions: defaultInteraction({ mouseWheelZoom: true }),
			view: this.defaultView,
		});
		this.timeMap = new Map({
			target: this.olTimeMap.current,
			layers: [...TIME_BASE_LAYERS, ...TIME_NAVIGATION_LAYERS],
			controls: defaultControl({
				collapsible: false,
				zoom: false,
			}).extend([new ScaleLine()]),
			interactions: defaultInteraction({
				mouseWheelZoom: true,
				doubleClickZoom: false,
				dragAndDrop: false,
				dragPan: false,
				keyBoardPan: false,
				keyBoardZoom: false,
			}),
			view: this.defaultView,
		});

		// Add Local Name overlay
		this.overlay = new Overlay({
			element: this.refs.olTool,
			offset: [10, -5],
			positioning: "bottom-left",
		});
		this.carMap.addOverlay(this.overlay);

		this.carMap.on("pointermove", (evt) => this.moveHandler(evt));

		this.carMap.on("click", (evt) => this.clickHandler(evt));

		this.setState({
			carMap: this.carMap,
			timeMap: this.timeMap,
			showTooltip: false,
		});

		// Get resolution and screen position info
		// usefull to send this info to mviewer
		this.carMap.on("moveend", (v) => {
			let view = v.map.getView();
			// required by OSI to get resolution and zoom info
			console.log("resolution >>> " + view.getResolution().toString());
			console.log("zoom >>> " + view.getZoom().toString());
			this.props.onSetNavigationView(
				`x=${view.getCenter()[0]}&y=${view.getCenter()[1]}&z=${view.getZoom()}`
			);
		});
	}
	/**
	 * See React doc to get more detail about Hooks
	 * https://fr.reactjs.org/docs/react-component.html#componentdidupdate
	 */
	componentDidUpdate(/*prevProps, prevState*/) {
		let { setRef, infos, onEnableTimeCompare } = this.props;
		// display selected layer by setRef value
		const visibleLayers = getAllRealVisibleLayers(this.carMap);
		let clickableLayer = visibleLayers.filter((x) => x.get("clickable"))[0];
		if (clickableLayer && config.getCapabilitiesUrl) {
			if (this.showLayer !== clickableLayer) {
				this.closeInfoPanel(this.props);
				const layerGsNamePre =  clickableLayer.getSource().getParams().layers.split("_")[0];
				const layerGsName = layerGsNamePre.concat("_800")				
				// Get list of years data available
				getCapabilitiesDimension(
					`${config.getCapabilitiesUrl}/${layerGsName}/wms?REQUEST=GetCapabilities`,
					{
						field: "Name",
						value: layerGsName,
					}
				).then((r) => {
					onEnableTimeCompare(r.yearsListAvailable.length > 1);
					if (setRef !== this.state.setRef) {
						this.setState({
							...r,
							yearsListAvailable: r.yearsListAvailable.length
								? r.yearsListAvailable
								: [],
							selectedYear: last(r.yearsListAvailable),
							selectedYearCompare: last(r.yearsListAvailable),
						});
					}
					this.setState({ setRef: setRef });
				});
				this.showLayer = clickableLayer;
			}
		}

		// Set visibility status for each main Map
		this.carMap
			.getLayers()
			.getArray()
			.forEach((layer) => {
				const propsLayer = layer.getProperties();
				if (propsLayer.isBaseLayer) return;
				const isVisible =
					!propsLayer.compo || propsLayer?.compo.toUpperCase() == setRef;
				// change visibility
				layer.setVisible(isVisible);
				if (layer.getSource().updateParams && this.state.selectedYear) {
					// will update WMS URL TIME params
					const fullTimeValue = this.state.fullTimeList.filter((x) =>
						x.includes(this.state.selectedYear)
					)[0];
					if (fullTimeValue) {
						layer.getSource().updateParams({
							time: fullTimeValue,
						});
					}
				}
			});

		// Set visibility status for each main Map
		// This map is the right map usefull to compare time data.
		// Will be display only with yearsListAvailable.length > 1 from getCapabilities
		if (this.timeMap) {
			this.timeMap
				.getLayers()
				.getArray()
				.forEach((layer) => {
					const propsLayer = layer.getProperties();
					if (propsLayer.isBaseLayer) return;
					const isVisible =
						!propsLayer.compo || propsLayer?.compo.toUpperCase() == setRef;
					layer.setVisible(isVisible);
					if (
						layer.getSource().updateParams &&
						this.state.selectedYearCompare
					) {
						// will update WMS URL TIME params
						const fullTimeValue = this.state.fullTimeList.filter((x) =>
							x.includes(this.state.selectedYearCompare)
						)[0];
						if (fullTimeValue) {
							layer.getSource().updateParams({
								time: fullTimeValue,
							});
						}
					}
				});
		}

		let selLayer = getLayerByName(this.carMap, "selectedLayer");
		let selSource = selLayer.getSource();
		selSource.clear();

		if (this.clickedFeature) {
			// simulate new click from same previous coordinates clicked
			// will display chart for any navigation mode at same coordinate
			this.updateClick(this.clickedFeature);
		}
		if (infos) {
			// create polygon too zoom to extent click infos
			// TODO : remplace infos by clicked GFI feature
			selLayer.setVisible(true);
			addFeatureFromInfos(infos, selSource, this.carMap);
		}

		// force to refresh map react state
		this.carMap && this.carMap.updateSize();
		if (this.timeMap) {
			// force to display map according to react state
			this.timeMap && this.timeMap.updateSize();
		}
	}

	render() {
		let {
			changeModal,
			responsiveModal,
			setRef,
			onSetLegendUrl,
			infos,
			infosCompare,
			url,
			urlCompare,
			timeActivated,
		} = this.props;

		let leg = "";
		let legendLayer = null;
		let showCompareMap = false;

		if (this.carMap) {
			legendLayer = this.carMap
				.getLayers()
				.getArray()
				.filter((layer) =>
					layer.getProperties().compo
						? layer.getProperties().compo.toUpperCase() === setRef
						: false
				);
			const layerSource = legendLayer[0]?.getSource();
			const viewScale = this.carMap.getView().getResolution()*3140;

			// get legend URL
			const legendUrl = layerSource?.getLegendUrl();
			leg =
				legendUrl && layerSource?.getParams().layers
					? `
				${legendUrl}
				&LAYER=${layerSource?.getParams().layers}
				&WIDTH=8
				&HEIGHT=10
				&legend_options=fontName:Helvetica;fontAntiAliasing:true;bgColor:0xFFFFFF;fontColor:0x707070;fontSize:6;dpi:220;
				&TRANSPARENT=true
				&FORMAT=image/png
				&SCALE=${viewScale}
			`
					: "";
		}

		// will update lenged box state to display new compo legend
		onSetLegendUrl(leg);

		// control if time Map is needed
		if (
			!isEmpty(this.state.yearsListAvailable) &&
			this.state.yearsListAvailable.length > 1 &&
			this.carMap &&
			timeActivated
		) {
			showCompareMap = true;
		}

		// update time map visibility according to previous condition
		this.timeMap && this.timeMap.updateSize();

		// Use slider as React fonctionnal component to force refresh
		const SliderUI = ({
			yearsListAvailable,
			selectedYear,
			onChange = () => {},
		}) => {
			// slider Component refresh on props change
			return (
				<Slider
					min={first(yearsListAvailable)}
					max={last(yearsListAvailable)}
					defaultValue={selectedYear}
					marks={keyBy(yearsListAvailable)}
					step={null}
					included={false}
					onChange={onChange}
					{...sliderStyle}
				/>
			);
		};
		return (
			<div className="map-wrapper">
				<div className="mapDiv">
					<div
						className="map"
						ref={this.olMap}
						id="map"
						style={{
							position: "relative",
							width: showCompareMap && timeActivated ? "50%" : "100%",
						}}>
						<div className="olTool" ref="olTool"></div>
						{//!isEmpty(this.state.yearsListAvailable) ? console.log("TimeList length >>> " + (this.state.yearsListAvailable.length*10).toString()) : console.log("TimeList empty")
						}
						{setRef && !isEmpty(this.state.yearsListAvailable) ? (
							<div className="select-year-slider-container">
								<div className="select-year-slider">
									{
										<SliderUI
											yearsListAvailable={this.state.yearsListAvailable}
											selectedYear={this.state.selectedYear}
											onChange={(y) => this.onSelectYear(y, "selectedYear")}
										/>
									}
								</div>
							</div>
						) : null}
					</div>
					<div
						className={`map ${showCompareMap ? "" : "d-none"}`}
						ref={this.olTimeMap}
						id="map-time"
						style={{ width: "50%" }}>
						<div className="select-year-slider-container">
							<div className="select-year-slider">
								{
									<SliderUI
										yearsListAvailable={this.state.yearsListAvailable}
										selectedYear={this.state.selectedYearCompare}
										onChange={(y) =>
											this.onSelectYear(y, "selectedYearCompare")
										}
									/>
								}
							</div>
						</div>
					</div>
				</div>
				{isEmpty(responsiveModal) && (
					<InfosBox
						onLoad={this.props.onLoad}
						onClose={() => this.closeInfoPanel(this.props)}
						url={url}
						urlCompare={urlCompare}
						tpl={config.templates?.infos}
						infos={infos}
						infosCompare={infosCompare}
					/>
				)}
				<BaseMapsSelector
					responsiveModal={responsiveModal}
					changeModal={changeModal}
					map={this.carMap}
					timeMap={this.timeMap}
					layers={BASE_LAYERS}
					layersCompare={TIME_BASE_LAYERS}
					updateShow={(v) => {
						this.setState({ showBaseMapSelector: v });
					}}
					show={this.state.showBaseMapSelector}
				/>
				<Button
					ref={this.infoBtnRef}
					id="infoBtn"
					className="d-lg-none"
					onClick={() =>
						this.setState({ showTooltip: !this.state.showTooltip })
					}>
					<FontAwesomeIcon icon={faInfo} style={customFontIconStyle} />
				</Button>
				<ReactOverlay
					target={this.infoBtnRef.current}
					show={this.state.showTooltip}
					placement="left">
					{(props) => (
						<Tooltip id="tooltip-info" {...props}>
							La comparaison temporelle des cartes &quot;côte à côte&quot; est
							uniquement disponible sur ordinateur.
						</Tooltip>
					)}
				</ReactOverlay>
			</div>
		);
	}
}

export default Carlitto;
